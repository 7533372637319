import { render, staticRenderFns } from "./resident.vue?vue&type=template&id=13e714b9&scoped=true&"
import script from "./resident.vue?vue&type=script&lang=js&"
export * from "./resident.vue?vue&type=script&lang=js&"
import style0 from "./resident.vue?vue&type=style&index=0&id=13e714b9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13e714b9",
  null
  
)

export default component.exports