<template>
  <div class="info">
    <van-cell-group>
        <div class="title">
            <div class="text" :class="{'isOld40': $isOld}">基本信息</div>
            <van-button class="editor-btn" plain type="info" size="mini"  @click="$router.push({path: '/house-add', query: {houseId}})">编辑</van-button>
        </div>
      <!-- <van-cell>
        <template #title>
          <span class="custom-title">基本信息</span>
          <van-button class="editor-btn" plain type="info" size="mini"  @click="$router.push({path: '/house-add', query: {houseId}})">编辑</van-button>
        </template>
      </van-cell> -->
      <van-field readonly v-model="dataForm.subareaName" label="所属小区" placeholder="未知"/>
      <van-field readonly v-model="dataForm.fullName" label="详细位置" placeholder="未知"/>
      <van-field readonly v-model="dataForm.areas" label="房屋面积 m²" placeholder="未知"/>
      <van-field readonly v-model="dataForm.floor" label="所在楼层" placeholder="未知"/>
      <van-field readonly v-model="dataForm.doorModelStr" label="户型" placeholder="未知"/>
      <van-cell
          readonly
          clickable
          name="标签管理"
          :value="labelStr"
          title="标签管理"
          :is-link="disabled"
          @click="showlabel"
      />
      <van-popup v-model="label" position="bottom"
                  @click-overlay="labelOverlay">
        <van-cascader
            title="请选择"
            value="value"
            :field-names="{ text: 'label', value: 'value', children: 'children' }"
            :options="userCommunityLabelTree"
            active-color="#1989fa"
            @close="labelClose"
            @change="labelFinish"
        />
      </van-popup>

      <van-cell hover-class="none" v-if="localLabels.length > 0">
        <template v-for="item in localLabels">
          <div class="vanTag" v-if="item.rightShow && !item.delete" :key="item.id" >
            <span :style="disabled ? 'border: none' : ''">{{ item.name }}</span>
            <van-icon style="padding: 0 10px" name="cross" v-if="disabled" @click="removeLabel(item.value)"/>
          </div>
        </template>

      </van-cell>
    </van-cell-group>

    <van-cell-group class="info-second" v-if="dataForm.gridId">
      <van-cell>
        <template #title>
          <span class="custom-title">网格信息</span>
          <div style="color:#387FF5;float:right" @click="firstVisible=!firstVisible"><van-icon :name="firstVisible?'arrow-up':'arrow-down'"></van-icon></div>
        </template>
      </van-cell>
      <div v-show="firstVisible">
        <van-cell title="所属网格">
          <van-field clearable clear-trigger="always" readonly v-model="dataForm.gridName" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="网格员">
          <van-field clearable clear-trigger="always" readonly v-model="dataForm.gridUser" placeholder="请输入" style="text-align: right"/>
        </van-cell>
      </div>
    </van-cell-group>

    <van-cell-group class="info-second">
      <van-cell>
        <template #title>
          <span class="custom-title" :class="{'isOld40': $isOld}">附属信息</span>
          <div style="color:#387FF5;float:right" @click="infoVisible=!infoVisible"><van-icon :name="infoVisible?'arrow-up':'arrow-down'"></van-icon></div>
        </template>
      </van-cell>
      <div v-show="infoVisible">
        <van-field readonly v-model="dataForm.useTypeStr" label="房屋属性" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.houseCard" label="不动产权证号" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.collectiveOwnership" label="共有情况" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.rentalRoomNum" label="出租间数" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.residentsLimit" label="限住人数" placeholder="未知" input-align="right"/>
        <van-field readonly :value="dataForm.manyHomeowners?'是':'否'" label="是否分户" placeholder="未知" input-align="right"/>
        <van-field readonly type="textarea" autosize rows="1" :value="dataForm.codeMappingEntity ? dataForm.codeMappingEntity.fullName : ''" label="标准地址名称" placeholder="未知" input-align="right"/>
        <van-field readonly type="textarea" autosize rows="1" v-model="dataForm.code" label="标准地址码" placeholder="未知" input-align="right"/>
        <van-field readonly v-model="dataForm.remark" placeholder="备注" type="textarea" autosize input-align="left" class="left"/>
        <van-row class="uploadBox">
          <van-col :span="24">
            <van-uploader
              v-model="urls"
              :readonly="true"
              :deletable="false"
              :show-upload="false"
            />
          </van-col>
        </van-row>
      </div>

    </van-cell-group>

    <!-- 自定义字段 -->
    <field-view ref="fieldView" :disabled="true" :readonly="true" :id="dataForm.id" />
    <div class="footer-botton">
        <div class="button" v-if="this.checkType == 0" @click="completeInspect">完成核查</div>
    </div>
    <div class="add-box" @mousedown="down"
      @touchstart="down"
      @mousemove="move"
      @touchmove="move"
      @mouseup="end"
      @touchend="end"
      draggable="true"
      ref="fu" @click="goAdd">
      <div class="add-img">
        <img class="add-icon" :src="require('@/assets/img/add.png')" alt="">
        <div class="text">新增、添加</div>
      </div>
    </div>
    <!-- <van-row class="btns">
      <van-col :span="24">
        <van-button type="info" size="large" round @click="$router.push({path: '/house-add', query: {houseId}})">编辑</van-button>
        <van-button type="info" size="large" v-if="this.checkType == 0" round @click="completeInspect">完成核查</van-button>
      </van-col>
    </van-row> -->
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {listComRightLabel} from "@/utils/common"
import {getImageStream} from '@/utils/index'
import {mapMutations, mapState} from 'vuex'
import fieldView from '../../components/field-view/field-view.vue';
let that
export default {
  components: {
    topBar,
    fieldView
  },
  props: ['checkType','houseInfo','labels','urls'],
  data() {
    return {
      // urls: [],
      completeiInspect: true,
      infoVisible: false,
      dataForm: {},
      problemList: [],
      repairList: [],
      visitList: [],
      carList: [],
      labelStr: '',
      disabled: false,
      label: false,
      localLabels: [],
      userCommunityLabelTree: [],
      firstVisible: true,
      flags: false, //控制使用
      position: {
        x: 0,
        y: 0,
      },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
    }
  },
  computed: {...mapState(['houseId'])},
  methods: {
    ...mapMutations(['setProblemData', 'setRepairData', 'setVisitId', 'setVisitData']),
    init() {
      //房屋标签
      listComRightLabel(function (labels) {
        that.userCommunityLabelTree = labels
      }, 2)
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/house/info/${this.houseId}`),
        method: 'post',
        // params: this.$http.adornParams({
        //   id: this.houseId
        // })
      }).then(({data}) => {
        if (data.code == 0) {
          this.dataForm = data.buildingHouse

          // 房屋标签
          var houseLabels = data.labels;
          var labels = []
          if (houseLabels != null) {
            labels = that.formatLabel(houseLabels, true);
          }
          that.labels = labels

          let urls = [];
          //获取图片流
          if (data.buildingHouse.imgUrl) {
            let imgUrls = data.buildingHouse.imgUrl.split(",");

            for (let i in imgUrls) {
              let u;
              let file = imgUrls[i];
              u = getImageStream(imgUrls[i]);
              let url = {
                uid: urls.length,
                status: "done", //uploading上传中，done上传完成，error上传失败
                url: u,
                fileType: "image/png",
                realUrl: file.replace(/\\/g, "/"),
              };
              urls.push(url);
            }
          }
          this.urls = urls;
          // 自定义字段
          this.$nextTick(() => {
            this.$refs.fieldView.init(this.houseId, 2, data.buildingHouse.community)
          })
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    // 报修列表
    getRepairList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/commonApp/repair/info/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: '1',
          limit: '2',
          houseId: this.houseId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          data.page.list.forEach(item => {
            if (item.fileDisplayVOs && item.fileDisplayVOs.length != 0) {
              let file = item.fileDisplayVOs[0]
              let url = file.relativeUrl
              if (url) {
                url = getImageStream(url)
                item["url"] = url
              }
            }
          })
          this.repairList = data.page.list
          // 加载状态结束
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },    // 报修列表
    getCarList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/infoCarInformation'),
        method: 'post',
        params: this.$http.adornParams({
          houseId: this.houseId,
          orgId: this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.carList = data.data || []
          // 加载状态结束
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    // 问题列表
    getProblemList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/problem/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: '1',
          limit: '2',
          houseId: this.houseId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          data.list.list.forEach(item => {
            if (item.problemFiles && item.problemFiles.length != 0) {
              let file = item.problemFiles[0]
              let url = file.filePath
              if (url) {
                url = getImageStream(url)
                item["url"] = url
              }
            }
          })
          this.problemList = data.list.list
          // 加载状态结束
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    // 走访列表
    getVisitList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/visit/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: '1',
          limit: '2',
          houseId: this.houseId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.visitList = data.page.list
          // 加载状态结束
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    // 跳转问题详情
    goProblemInfo(id, status) {
      let data = {'id': id, 'status': status}
      this.$store.commit('setProblemData', data)
      this.$router.push('/problem-info')
    },
    goProblem: function () {
      this.$router.push({path: '/problem', query: {houseId: this.houseId + ''}})
    },
    // 跳转报修详情
    goRepairInfo (id, status) {
      let data = {'id':id,'status':status}
      this.$store.commit('setRepairData', data)
      this.$router.push('/repair-info')
    },
    goRepair() {
      this.$router.push({path: '/repair', query: {houseId: this.houseId + ''}})
    },
    goVisitInfo(id, visitType, location) {
      let type = ''
      if (visitType == 2) {
        type = '3'
      } else {
        type = location ? '1' : '2'
      }
      this.$router.push({path: '/visit-add', query: {id: id, type: type}})
    },
    goVisit(id) {
      this.$router.push({path: '/visit', query: {houseId: this.houseId + '', subarea: this.dataForm.subarea + '',
        buildingId: this.dataForm.buildingId + '', unitId: this.dataForm.unitId + '', buildingStr: this.dataForm.subareaName + '-' + this.dataForm.fullName}})
    },
    goCar(){
      this.$router.push({path: '/car', query: null})
    },
    goCarInfo(id,subarea){
      this.$router.push({path: '/car-add', query: {id:id, subarea: subarea}})
    },

    //
    showlabel() {
      // //debugger
    //   if (this.disabled) {
        this.label = true
    //   }
    },
    //标签级联的关闭
    labelOverlay() {
      // this.setData({ 'control.communityLabelCascader= false })
      let temporaryLabel = that.temporaryLabel[0]
      if (!temporaryLabel) {
        return
      }
      for (let i in that.labels) {
        let label = that.labels[i]
        if (label.value == temporaryLabel.value) {
          return
        }
      }

      temporaryLabel["delete"] = false

      that.labels.push(temporaryLabel)
      that.dataForm.labels.push(temporaryLabel.ids)
    },
    labelClose(e) {
      this.label = false
    },
    labelFinish(e) {
      let value = []
      e.selectedOptions.map(e => value.push(e.value))
      let map = {}
      map["value"] = value
      map['options'] = e.selectedOptions
      let temporaryLabel = this.formatLabel(map, false)
      that.temporaryLabel = temporaryLabel
    },
    //删除标签
    removeLabel(value) {
      for (let i in that.dataForm.labels) {
        let label = that.dataForm.labels[i] + ''
        let labelArray = label.split(",")
        let id = labelArray[labelArray.length - 1]
        if (id == value) {
          that.dataForm.labels.splice(i, 1)
        }
      }

      for (let i in that.labels) {
        let label = that.labels[i]
        if (label.value == value) {
          that.labels[i]["delete"] = true
        }
      }
      that.dataForm.labels = that.dataForm.labels
      that.labels = that.labels
      this.$forceUpdate()
    },
     /**
     * 处理标签显示效果\n
     *
     * @param resLabels
     * @param isOld 是否后端传入的旧数据
     * @returns {[]}
     */
    formatLabel: function (resLabels, isOld) {
      var labels = [];
      if (isOld) {
        for (var i = 0; i < resLabels.length; i++) {
          //是否存在第二级
          var exists2 = false;
          if (resLabels[i].parentId == 0) {
            for (var j = 0; j < resLabels.length; j++) {
              if (resLabels[j].parentId == resLabels[i].id) {
                exists2 = true;
                //是否存在第三级标签
                var exists3 = false;
                for (var k = 0; k < resLabels.length; k++) {
                  if (resLabels[k].parentId == resLabels[j].id) {
                    exists3 = true;
                    labels.push({
                      value: resLabels[k].id,
                      label: resLabels[k].name,
                      name: resLabels[i].name + "：" + resLabels[j].name + "(" + resLabels[k].name + ")",
                      ids: resLabels[i].id + "," + resLabels[j].id + "," + resLabels[k].id,
                      rightShow: resLabels[k].rightShow,
                      rightUpdate: resLabels[k].rightUpdate
                    })
                  }
                }
                if (!exists3) {
                  //不存在第三级，则加入第二级
                  labels.push({
                    value: resLabels[j].id,
                    label: resLabels[j].name,
                    name: resLabels[i].name + "：" + resLabels[j].name,
                    ids: resLabels[i].id + "," + resLabels[j].id,
                    rightShow: resLabels[j].rightShow,
                    rightUpdate: resLabels[j].rightUpdate
                  })
                }
              }
            }
            if (!exists2) {
              //不存在第二级，则加入第一级
              labels.push({
                value: resLabels[i].id,
                label: resLabels[i].name,
                name: resLabels[i].name,
                ids: resLabels[i].id,
                rightShow: resLabels[i].rightShow,
                rightUpdate: resLabels[i].rightUpdate
              })
            }
          }
        }
      } else {
        let ids = resLabels.value
        let options = resLabels.options;
        if (ids.length == 1) {
          let option = options[0]
          //只有第一级
          labels.push({
            value: option.value,
            label: option.label,
            name: option.label,
            ids: option.value,
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 2) {
          let option1 = options[0]
          let option2 = options[1]
          //不存在第三级，则加入第二级
          labels.push({
            value: option2.value,
            label: option2.label,
            name: option1.label + "：" + option2.label,
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 3) {
          let option1 = options[0]
          let option2 = options[1]
          let option3 = options[2]
          //不存在第三级，则加入第二级
          labels.push({
            value: option3.value,
            label: option3.label,
            name: option1.label + "：" + option2.label + "(" + option3.label + ")",
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        }
      }
      return labels;
    },
    completeInspect() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/check/task/info/finish'),
        method: 'get',
        params: this.$http.adornParams({
          houseId: this.houseId
        })
      }).then(({data}) => {
        if(data.code === 0) {
          this.$toast.success({
            duration: 1500,
            forbidClick: true,
            message: '已完成核查',
            onClose: () => {
              this.$router.go(-1)
            }
          });
        }
      })
    },
    goAdd() {
      this.dataForm.houseFlag = true
      this.$router.push({path: '/userRes-info', query: this.dataForm})
    },
    // 新增按钮移动
    down(event) {
      this.flags = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = this.$refs.fu.offsetLeft;
      this.dy = this.$refs.fu.offsetTop;
    },
    move(event) {
      if (this.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;
        this.xPum = this.dx + this.nx;
        this.yPum = this.dy + this.ny;
        let width = window.innerWidth - this.$refs.fu.offsetWidth; //屏幕宽度减去自身控件宽度
        let height = window.innerHeight - this.$refs.fu.offsetHeight; //屏幕高度减去自身控件高度
        this.xPum < 0 && (this.xPum = 0);
        this.yPum < 0 && (this.yPum = 0);
        this.xPum > width && (this.xPum = width);
        this.yPum > height && (this.yPum = height);
        this.$refs.fu.style.left = this.xPum + "px";
        this.$refs.fu.style.top = this.yPum + "px";
        //阻止页面的滑动默认事件
        document.addEventListener("touchmove",function (event) {
          event.preventDefault();
        },false);
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false;
    },
  },
  created() {
    that = this
    this.init()
    this.dataForm = this.houseInfo
    this.localLabels = this.labels
    // this.getRepairList()
    // this.getProblemList()
    // this.getVisitList()
    // this.getCarList()
  }
}
</script>
<style scoped>
.list-info {
  padding-bottom: 20px !important;
}
.list-item {
  border: 1px solid #eee;
}
.list-item:nth-child(2) {
  margin-top: 20px !important;
}
.left >>> .van-field__control {
  text-align: left !important;
}
</style>
<style lang="scss" scoped>
.info {
  padding: 0;
  margin-top: 24px !important;
}
.title {
    height: 96px;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text {
        font-size: 36px;
        // font-family: 'PingFang Bold';
        font-weight: 700;
        color: #333333;
    }
}
.info-second {
    margin-top: 24px;
}
.vanTag {
  margin: 0 0 5px 5px;
  border-radius: 5px;
  border: 1px solid #007AFF;
  background: rgba(0, 122, 255, 0.1);
  //padding-right: 20px;
  float: left;

  span {
    display: inline-block;
    padding: 0 17px;
    //margin-right: 20px;
    font-size: 24px;
    color: #007AFF;
    height: 56px;
    line-height: 56px;
    border-right: 1px solid #007AFF;
  }
}
.editor-btn {
    width: 116px;
    height: 58px;
    border-radius: 8px;
    border: 2px solid #4581F8;
    font-size: 30px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #4581F8;
    text-align: center;
    line-height: 58px;
}
.footer-botton {
    margin: 40px 0;
    padding: 0 30px;
    .button {
        height: 88px;
        background: #4581F8;
        border-radius: 8px;
        line-height: 88px;
        text-align: center;
        font-size: 32px;
        font-family: 'PingFang Bold';
        font-weight: 500;
        color: #FFFFFF;
    }
}
</style>
